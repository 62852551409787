<template>
  <transition>
    <el-col :lg="span" :md="span" :sm="12" :xs="12">
      <div class="card">
        <div class="p-4 text-right">
          <div @click="(data.action)? data.action() : null">
            <i :class="data.icon" class="icon"></i>
            <div> {{ data.title }}</div>
            <h4 class="d-inline-block ml-2">{{ boardInfo.count }}</h4>
          </div>

          <!--<div class="action mt-3" v-if="data.action" @click="data.action">
            {{ data.actionLabel }}
          </div>-->

          <el-collapse v-if="propsLength > 0">
            <el-collapse-item name="1" title="Info">
              <div :key="key" class="text-left text-capitalize p-0 m-0" v-for="(prop, key) in propsList">
                {{ prop }} {{ key }}
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
    </el-col>
  </transition>
</template>

<script>
export default {
  props: {
    span: {
      default: 6
    },
    data: {
      required: true
    }
  },
  mounted() {
    let action = (this.data.apiAction) ? this.data.apiAction : 'dashboardInfo'
    $store.dispatch(this.data.apiStore + '/' + action).then(r => {
      this.boardInfo = r
      this.propsList = r.propsList
      if (typeof this.propsList == 'array')
        this.propsLength = this.propsList.length
      else
        this.propsLength = Object.keys(this.propsList).length
    })
  },
  data() {
    return {
      boardInfo: {},
      propsList: [],
      propsLength: 0
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
  border: none;
  margin-bottom: 30px;
  position: relative;
  display: -ms-flexbox;
  flex-direction: column;
  font-size: 0.875em;
  font-weight: 600;
  min-height: 120px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.card label {
  color: #1b1e21;
}

.card .action {
  display: inline-block;
  text-align: left;
  color: #fff;
  font-size: 8pt;
  font-weight: bold;
  cursor: pointer;
  border: 0px solid #031b4d;
  background-color: #031b4d;
  margin-left: 5px;
  width: fit-content;
  transition: background-color .3s ease-in-out;
  padding: 2px 12px;
}

.card .action:hover {
  background-color: transparent;
  color: #031b4d;
}

/*.card:hover .icon {
  top: -25px;
}*/

.card .icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -15px;
  font-size: 35px;
  border-radius: 8px;
  display: flex;
  color: #999;
  font-size: 62pt;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

</style>
